<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import List from './list'
//import Form from './form'

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
    List,
    //Form,
  },
  data() {
    return {
      title: "Movement",
      items: [
        {
          text: "VMCore"
        },
        {
          text: "Movement",
          active: true
        },
      ]
    };
  },
  methods :{
    onActiveChange(b) {
      this.$refs.list.setActive(!b);
    },
    onRefresh(){
      this.$refs.list.fetchData()
    },
    // onEdit(machineId){
    //   this.$refs.form.initUpdate(machineId)
    // }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-12">
        <!-- <Form ref="form" @refresh="onRefresh" @active_change="onActiveChange"/> -->
        <List ref="list"/>
      </div>
      
    </div>
  </Layout>
</template>