import { authHeader } from './auth-header';
import axiosInstance from "./client"

export const movementService = {
    getAll,
};

function getAll(filter) {
    const requestOptions = {
        url : '/api/v1/user/movement',
        method: 'GET',
        params: filter,
        headers: authHeader()
    };
    return axiosInstance(requestOptions)
    //return fetch(`/api/v1/user/machine_type/` + machineTypeId, requestOptions).then(handleResponse);
}